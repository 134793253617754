import React from "react";
import "./css/Communication.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import arkaplan from "./images/iletisim.jpeg";
import {
    faMapMarker,
    faPhone,
    faEnvelope,
    faFax,
} from "@fortawesome/free-solid-svg-icons";
function Communication() {
    return (
        <div
            className="communication"
            style={{ backgroundImage: "url(" + "/images/iletisim.jpeg" + ")" }}
        >
            <h1>İletişim Bilgileri</h1>

            <hr />
            <div className="communication-container">
                {/* <img src={img1} alt="" /> */}
                <div className="communication-information">
                    <p>
                        <FontAwesomeIcon icon={faMapMarker} size="1x" />
                        {"   "} İOSB Metal İş San Sit 4/A. Blok No:44
                        İkitelli-Başakşehir/ Istanbul
                    </p>
                    <p>
                        <FontAwesomeIcon icon={faPhone} size="1x" /> {"   "}+90
                        212 549 86 74
                    </p>
                    <p>
                        <FontAwesomeIcon icon={faEnvelope} size="1x" /> {"   "}
                        info@ozsahinler.com.tr
                    </p>
                    <p>
                        <FontAwesomeIcon icon={faFax} size="1x" />
                        {"   "}+90 212 549 86 67 D:11-12
                    </p>
                </div>
            </div>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1303.8035218329658!2d28.801460546247487!3d41.06871387373859!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6ce211ab3926efb6!2s%C3%96z%C5%9Fahinler%20Makine%20H%C4%B1rdavat%20Teknik%20Malzeme%20Tic.%20San.%20Ltd.%20%C5%9Eti!5e1!3m2!1sen!2str!4v1644158709631!5m2!1sen!2str"
                width="50%"
                height="300"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
            ></iframe>
        </div>
    );
}

export default Communication;
