// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import language files
import enTranslation from './locales/en.json';
import trTranslation from './locales/tr.json';
import zhTranslation from './locales/zh.json';
import esTranslation from './locales/es.json';
import deTranslation from './locales/de.json';
import frTranslation from './locales/fr.json';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

i18n.use(I18nextBrowserLanguageDetector).use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    tr: { translation: trTranslation },
    zh: { translation: zhTranslation },
    es: { translation: esTranslation },
    de: { translation: deTranslation },
    fr: { translation: frTranslation },
  },
  // lng: 'tr', // default language
  fallbackLng: 'tr', // fallback language when translation is missing
  interpolation: {
    escapeValue: false, // React already escapes from XSS
  },
  detection: {
    order: ['cookie', 'localStorage', 'navigator'],
    caches: ['cookie', 'localStorage'],
  }
});

export default i18n;
