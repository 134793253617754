import React, { useState, useEffect } from "react";
import "./css/About.css";
import { useTranslation } from "react-i18next";
  
function About() {
  const { t } = useTranslation();
  return (
    <div className="about" style={{ backgroundImage: "url(" + "/images/5J3A3873-blurred.jpg" + ")" }}>
      <div className="about-container">
        <div className="about-texts">
          {/* <h1>Hakkımızda</h1> */}
          <h1>{t("about-us.title")}</h1>
          <p>
            {/* Şirketimiz 1990 yılında ahşap kesiminde kullanılan şerit testere
            satışı yapmak üzere Karaköy de kurulmuştur. */}
            {t("about-us.first-paragraph")}
          </p>
          <p className="about-texts-bold">
            {/* 1994 yılında artan ihtiyaca cevap verebilmek için şerit testere
            üretimine başlayan şirketimiz , 2002 yılında MARTİN MİLLER
            markasının Türkiye distribütörlüğünü almıştır. */}
            {t("about-us.second-paragraph")}
          </p>
          <p>
            {/* Ürün çeşitliliğimizi artırarak ahşap kesen şerit testerelere ilave
            olarak Et ve Balık kesen şerit testereleri , Bi-metal şerit
            testereleri , dişleri sertleştirilmiş ahşap kesen şerit testereleri
            , TCT elmas daire testereler , ahşap için planya bıçakları ithalat
            ve satış faaliyetlerimize devam etmekteyiz. */}
            {t("about-us.third-paragraph")}
          </p>
          <h2>{t("about-us.our-goals")}</h2>
          <p>
            {/* Kaliteli hizmet anlayışını daima ön planda tutup , müşteri
            taleplerine doğru ve hızlı çözümler üreterek , kuruluşundan bugüne
            kadar kazanmış olduğu güvenilir imajından asla taviz vermeden
            faaliyetlerini sürdürmektir. */}
            {t("about-us.fifth-paragraph")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
