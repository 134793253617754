import React, { useState, useEffect } from "react";
import "./css/Products.css";
import "./scss/textanimation.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import img2 from "./images/5J3A3907.jpg";
// import img9 from "./images/5J3A4469.jpg";
// import img10 from "./images/terscevrilmis.jpg";
// import img11 from "./images/5J3A4461.jpg";
// import welherts from "./images/Welherts.jpg";
// import blader from "./images/blade.png";
// import bohler from "./images/bohler-miller.jpeg";
// import wabert from "./images/wabert.png";

import { useTranslation } from "react-i18next";
const Products = () => {
    const { t } = useTranslation();
    const [cssVisible, SetCssVisible] = useState("animation-visible");
    const change = () => {
        SetCssVisible("animation-unvisible");
        setTimeout(() => {
            SetCssVisible("animation-visible");
        }, 500);
    };

    useEffect(() => {
        change();
    }, []);

    return (
        <>
            <div className="carousel-container">
                <Carousel
                    axis="horizontal"
                    autoPlay={true}
                    dynamicHeight={true}
                    infiniteLoop={true}
                    stopOnHover={false}
                    swipeable={true}
                    transitionTime={1000}
                    renderThumbnails={false}
                    renderThumbs={() => {}}
                    onChange={change}
                    onClick={() => {}}
                    interval={4750}
                >
                    <div key={"1"}>
                        <div
                            className={
                                "animated-title animated-title-with-3-row " +
                                cssVisible
                            }
                        >
                            <div className={"text-top smallHeader"}>
                                <div>
                                    <span className={"text-top-small2"}>
                                        {/* Dişleri Sertleştirilmiş, Bileyli ve
                                        Çaprazlı Ahşap Kesen Şerit Testereler */}
                                        {t("home-page.tempered-and-bevelled-wood-cutting-steel-band-saw")}
                                    </span>
                                </div>
                            </div>
                            <div className="border-div"></div>
                            <div className="text-bottom">
                                {/* <img className="text-bottom-img" src={bohler} alt="" /> */}
                            </div>
                        </div>

                        <img
                            className={"carousel-background"}
                            src={ "/images/5J3A3907.jpg"}
                            alt=""
                        />
                    </div>
                    <div key={"2"}>
                        <div className={"animated-title " + cssVisible}>
                            <div className={"text-top"}>
                                <div>
                                    <span className="text-top-small">
                                        {/* Ahşap Kesen Çelik Şerit Testereler */}
                                        {t("home-page.wood-cutting-steel-band-saw")}
                                    </span>
                                </div>
                            </div>
                            <div className="border-div"></div>
                            <div className="text-bottom">
                                <img
                                    className="text-bottom-img"
                                    src={ "/images/normals/bohler-miller.jpeg"}
                                    alt=""
                                />
                            </div>
                        </div>

                        <img
                            className={"carousel-background"}
                            src={ "/images/5J3A4469.jpg"}
                            alt=""
                        />
                    </div>
                    <div key={"3"}>
                        <div className={"animated-title " + cssVisible}>
                            <div className={"text-top"}>
                                <div>
                                    {/* Et ve Balık Testereleri */}
                                    <span className="text-top-small">
                                        {t("home-page.meat-and-fish-saw")}
                                    </span>
                                </div>
                            </div>
                            <div className="border-div"></div>
                            <div className="text-bottom">
                            </div>
                        </div>

                        <img
                            className={"carousel-background"}
                            src={ "/images/terscevrilmis.jpg"}
                            alt=""
                        />
                    </div>
                    <div key={"4"}>
                        <div className={"animated-title " + cssVisible}>
                            <div className={"text-top"}>
                                <div>
                                    {/* M42 Bi-Metal Şerit Testere */}
                                    <span className="text-top-small">
                                        {t("home-page.m42-bi-metal-steel-band-saw")}
                                    </span>
                                </div>
                            </div>
                            <div className="border-div"></div>
                            <div className="text-bottom">
                                <img
                                    className="text-bottom-img"
                                    src={ "/images/normals/Welherts.jpg"}
                                    alt=""
                                />
                            </div>
                        </div>

                        <img
                            className={"carousel-background rotate"}
                            src={ "/images/blade.png"}
                            alt=""
                        />
                    </div>
                    <div key={"5"}>
                        <div className={"animated-title " + cssVisible}>
                            <div className={"text-top"}>
                                <div>
                                    {/* TCT Elmas Uçlu Daire Testereler */}
                                    <span className="text-top-small">
                                        {t("home-page.tct-diamond-edged-disc-saw")}
                                    </span>
                                </div>
                            </div>
                            <div className="border-div"></div>
                            <div className="text-bottom">
                                <img
                                    className="text-bottom-img"
                                    src={ "/images/normals/wabert.png"}
                                    alt=""
                                />
                            </div>
                        </div>

                        <img
                            className={"carousel-background"}
                            src={ "/images/5J3A4461.jpg"}
                            alt=""
                        />
                    </div>
                </Carousel>
            </div>
        </>
    );
};

export default Products;
